
import { defineComponent } from 'vue';
import { IonTabs, IonPage, IonRouterOutlet } from '@ionic/vue';
import { ellipse } from 'ionicons/icons';

export default defineComponent({
  name: 'TabsPage',
  components: { IonTabs, IonPage, IonRouterOutlet },
  setup() {
    return {
      ellipse, 
    }
  }
});
